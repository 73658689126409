import type { KeywordListAnalysisGroup } from "~/types";

export default function getKeywordListAnalysisGroupMatchPercentage({
  analysisGroup,
  analysisGroupIndices,
}: {
  analysisGroup: KeywordListAnalysisGroup;
  analysisGroupIndices?: number[];
}): number {
  let part: number;
  if (analysisGroupIndices) {
    part = analysisGroupIndices
      .map((i) => analysisGroup.keywordListAnalyses[i])
      .reduce((acc, curr) => acc + curr.matchCount, 0);
  } else {
    part = analysisGroup.keywordListAnalyses.reduce(
      (acc, curr) => {
        const score = curr.keywordList.score || 0;
        const matchCount = curr.matchCount * score
        return acc + matchCount;
      },
      0
    );
  }

  return analysisGroup.matchCountFromAllLists
    ? Math.round((part / analysisGroup.matchCountFromAllLists) * 100)
    : 0;
}
