import { DEFAULT_REG_EXP, REG_EXP_TOKEN } from "~/models/keywords.constants";
import type { KeywordList } from "~/models/keywords.server";

const highlightText = (inputText: string, wordLists: KeywordList[]) => {
  let outputText = inputText;
  wordLists.forEach(({ keywords, color, regExp, label }) => {
    keywords.forEach((keyword) => {
      // Wrap any matches in a span with the appropriate class so it can be styled.
      const rgx = (regExp || DEFAULT_REG_EXP).replace(
        REG_EXP_TOKEN,
        keyword.word
      );
      const rule = new RegExp(rgx, "gim");

      // Replace all instances of the keyword with the keyword wrapped in a 
      // span with the appropriate CSS class.
      outputText = outputText.replaceAll(
        rule,
        function (match) {
          return `<span class="color-${color}" style="font-weight: bold">${match}</span>`;
        }
      );
    });
  });
  return outputText;
};

export default function HighlightedOutput({
  inputText,
  wordLists,
}: {
  inputText: string;
  wordLists: KeywordList[];
}) {
  return (
    <pre
      className="mb-4 mt-1 h-full w-full p-2 rounded border border-gray-950 highlighted-output whitespace-pre-line"
      dangerouslySetInnerHTML={{
        __html: highlightText(inputText, wordLists),
      }}
    ></pre>
  );
}
