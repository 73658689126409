import LineDial from "./LineDial";

function getPercentForLineDial(level: number) {
  const maxLevel = 5;
  const sectionSize = 100 / maxLevel;
  const percent = sectionSize * level;
  if (level === maxLevel) {
    return percent;
  } else {
    return percent - sectionSize / 2;
  }
}

function getColorFromLevel(level: number, colors: string[]) {
  return colors[Math.min(level - 1, colors.length - 1)];
}

export default function DialCollection({
  hasInput,
  audienceCentricLevel,
  clientLevel,
  questionCount,
  clientPercent,
  audienceCentricPercent,
}: {
  hasInput: boolean;
  audienceCentricLevel: number;
  clientLevel: number;
  questionCount: number;
  clientPercent: number;
  audienceCentricPercent: number;
}) {
  // The the user has provided input but there are no audience-centric pronouns,
  // we should still show the dial at 1% to indicate that there is some focus
  // on the audience but it is minimal.
  if (hasInput && audienceCentricPercent === 0) {
    audienceCentricPercent = 1;
  }

  const focusBarColor = getColorFromLevel(audienceCentricLevel, [
    "#abf7b1",
    "#83f28f",
    "#5ced73",
    "#39e75f",
    "#1FD655",
  ]);
  const focusHighLevelLabelColor = "#1FD655";
  const focusHighLevelLabelStroke = "#001100";

  const meaningBarColor = getColorFromLevel(clientLevel, [
    "#88bee0",
    "#75a2bf",
    "#5e8cad",
    "#46769b",
    "#2F5FBA",
  ]);
  const meaningHighLevelLabelColor = "#2F5FBA";
  const meaningHighLevelLabelStroke = "#000011";

  return (
    <div className="flex flex-grow flex-row items-end justify-around pb-4 text-center sm:justify-center">
      <span className="flex w-28 flex-col items-center font-bold sm:mr-16 sm:w-32 md:mr-20 md:w-44">
        <LineDial
          percent={audienceCentricPercent}
          barColor={focusBarColor}
          currentLevelLabelColor={focusHighLevelLabelColor}
          currentLevelLabelStroke={focusHighLevelLabelStroke}
        />
        <span className="mt-[-1.3em] text-sm text-being-ei-blue md:text-xl">
          Focus
        </span>
      </span>
      <span className="mx-4 flex flex-col items-center text-sm font-bold sm:text-lg md:text-lg">
        <span className="text-being-ei-blue">Bonus Points</span>
        <span className="rounded border border-gray-600 px-1 text-xl text-being-ei-blue">
          {questionCount}
        </span>
      </span>
      <span className="flex w-28 flex-col items-center font-bold sm:ml-16 sm:w-32 md:ml-20 md:w-44">
        <LineDial
          percent={clientPercent}
          barColor={meaningBarColor}
          currentLevelLabelColor={meaningHighLevelLabelColor}
          currentLevelLabelStroke={meaningHighLevelLabelStroke}
        />
        <span className="mt-[-1.3em] text-sm text-being-ei-blue md:text-xl">
          Meaning
        </span>
      </span>
    </div>
  );
}
