import type { SerializeFrom } from "@remix-run/node";

import type { KeywordListAnalysisGroup, KeywordListAnalysis } from "~/types";
import { DEFAULT_REG_EXP, REG_EXP_TOKEN } from "~/models/keywords.constants";
import { KeywordList } from "~/models/keywords.server";

export default function getKeywordListAnalysisGroup(
  inputText: string,
  keywordLists: SerializeFrom<KeywordList[]>,
  totalWords?: number,
  description?: string
): KeywordListAnalysisGroup {
  const keywordListAnalyses: KeywordListAnalysis[] = keywordLists.map(
    (keywordList) => {
      const { keywords, regExp } = keywordList;
      const matchCount = keywords.reduce((matchCount, keyword) => {
        const rgx = (regExp || DEFAULT_REG_EXP).replace(
          REG_EXP_TOKEN,
          keyword.word
        );
        const rule = new RegExp(rgx, "gi");
        const matches = inputText.match(rule);
        return matches ? matchCount + matches.length : matchCount;
      }, 0);
      return {
        keywordList,
        matchCount,
      };
    }
  );

  const totalMatches =
    totalWords ||
    keywordListAnalyses.reduce(
      (total, analysis) => analysis.matchCount + total,
      0
    );

  return {
    keywordListAnalyses,
    matchCountFromAllLists: totalMatches,
    description,
  };
}
