import { useMemo } from "react";
import { useInputText } from "~/contexts/InputTextContext";
import getKeywordListAnalysisGroupMatchPercentage from "~/utils/getKeywordAnalysisGroupMatchPercentage";
import getKeywordListAnalysisGroup from "~/utils/getKeywordListAnalysisGroup";
import type { KeywordList } from "~/models/keywords.server";

export function countQuestions(inputText: string) {
  const whyQuestions = inputText.match(/\b(why)\b[^?.!]*[?]/gi)?.length || 0;
  const allQuestions = inputText.match(/[A-Za-z]\?/g)?.length || 0;
  // Don't count questions that start with "why" since they are generally rhetorical.
  return allQuestions - whyQuestions;
}

export default function useInputAnalysis({
  keywordLists,
}: {
  keywordLists: KeywordList[];
}) {
  const inputTextContext = useInputText();
  const { analysis, inputText, setAnalysis, setQuestionCount } =
    inputTextContext;
  const totalWordCount = inputText.split(" ").filter((w) => w !== "").length;

  useMemo(() => {
    if (!keywordLists || keywordLists.length < 2) {
      return;
    }

    const pronounAnalysisGroup = getKeywordListAnalysisGroup(
      inputText,
      // Include two lists as part of this analysis group:
      // Audience-Centric Pronouns
      // Client-Centric Pronouns
      keywordLists.filter((list) => list.group === "pronouns"),
      // Pass `undefined` to calculate the total word count based on the match
      // count from all KeywordListAnalysis items in the group.
      undefined,
      "Based on total pronoun word count"
    );

    const clientAnalysisGroup = getKeywordListAnalysisGroup(
      inputText,
      // Include client keywords only in this group
      [keywordLists[2]].filter((i) => !!i),
      // The % of client keywords is based on the total word count, not the total
      // number of _matched_ words.
      totalWordCount,
      "Based on total word count"
    );

    setQuestionCount(countQuestions(inputText));

    setAnalysis([pronounAnalysisGroup, clientAnalysisGroup]);
  }, [keywordLists, inputText, setAnalysis, totalWordCount, setQuestionCount]);

  const pronounPercent = analysis[0]
    ? getKeywordListAnalysisGroupMatchPercentage({
        analysisGroup: analysis[0],
      })
    : 0;

  const clientPercent = analysis[1]
    ? getKeywordListAnalysisGroupMatchPercentage({
        analysisGroup: analysis[1],
      })
    : 0;


  const pronounMaxPercent = keywordLists[1]?.displayMax || 100;
  const clientMaxPercent = keywordLists[2]?.displayMax || 100;

  // Alternative way to calculate dial percent: Decrement by 1% to make client keywords (which has a lower max percent) look more like audience keywords
  // const dialPercentClientKeywords = ((clientPercent === 0 ? 0 : clientPercent - 1) / clientMaxPercent) * 100;
  const dialPercentClientKeywords = (clientPercent / clientMaxPercent) * 100;
  const dialPercentAudienceKeywords =
    (pronounPercent / pronounMaxPercent) * 100;

  return {
    pronounPercent,
    pronounMaxPercent,
    clientPercent,
    clientMaxPercent,
    inputTextContext,
    dialPercentClientKeywords,
    dialPercentAudienceKeywords,
  };
}
